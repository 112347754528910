// Generated by CoffeeScript 1.10.0
(function() {
  (function(p, $, d, w) {
    var MealPlanner;
    MealPlanner = (function() {
      var self;

      self = false;

      function MealPlanner() {
        self = this;
        this.isMobileDevice = /Android|Kindle|Silk|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
        this.dayMap = {
          1: 'Lunedì',
          2: 'Martedì',
          3: 'Mercoledì',
          4: 'Giovedì',
          5: 'Venerdì',
          6: 'Sabato',
          7: 'Domenica'
        };
      }

      MealPlanner.prototype.isRunnable = function() {
        return $('#meal-planner-table-desktop').length > 0 && $(w).width() >= 768;
      };

      MealPlanner.prototype.ready = function() {
        self = this;
        self.mealTable = $('#meal-planner-table-desktop');
        return $(w).load(function() {
          if (self.isRunnable()) {
            self.bindings();
            self.load();
          }
          if (self.getUrlParameter('print_page') === '1') {
            return w.setTimeout(function() {
              w.print();
            }, 1000);
          }
        });
      };

      MealPlanner.prototype.clearMessages = function() {
        return w.setTimeout(function() {
          $('#messages').html("");
        }, 10000);
      };

      MealPlanner.prototype.closePopup = function() {
        return $('#modal-popup-dishes').modal('hide');
      };

      MealPlanner.prototype.showLoadingPopup = function() {
        return $('#modal-popup-dishes .loading').show();
      };

      MealPlanner.prototype.hideLoadingPopup = function() {
        return $('#modal-popup-dishes .loading').hide();
      };

      MealPlanner.prototype.showLoading = function() {
        return $('#meal-planner-table-desktop .loading').show();
      };

      MealPlanner.prototype.hideLoading = function() {
        return $('#meal-planner-table-desktop .loading').hide();
      };

      MealPlanner.prototype.setMessages = function(messages) {
        if (messages.constructor === Array) {
          return $.each(messages, function(key, message) {
            return self.setMessage(message);
          });
        } else {
          return self.setMessage(messages);
        }
      };

      MealPlanner.prototype.setMessage = function(msg) {
        return $('#messages').prepend('<span>' + msg + '<span class="close-msg">x</span></span>');
      };

      MealPlanner.prototype.load = function() {};

      MealPlanner.prototype.openLinkList = function(arr) {
        return $.each(arr, function(key, link) {
          if (link !== '') {
            return w.open(link);
          }
        });
      };

      MealPlanner.prototype.getSliderUser = function() {
        return $('#dish-list-slider-user')[0].swiper;
      };

      MealPlanner.prototype.getSliderRedazione = function() {
        return $('#dish-list-slider-redazione')[0].swiper;
      };

      MealPlanner.prototype.getSliderObject = function(idSlider) {
        return $(idSlider)[0].swiper;
      };

      MealPlanner.prototype.removeDish = function(elem) {
        var cell, dataSend, mealplanId;
        self.showLoading();
        cell = $(elem).parents('.dish-container');
        mealplanId = cell.attr("data-mealplan-id");
        dataSend = {
          'mealplan_id': mealplanId,
          'action': 'delete_dish_mealplan',
          'is_ajax': 1,
          'weekday': cell.attr('data-day-id'),
          'meal_id': cell.attr('data-meal-id')
        };
        $.ajax({
          type: 'POST',
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.dish_type === 'exception') {
              cell.attr("data-dish-id", dataSend.dish_id);
              cell.attr("data-dish-type", 'default');
              cell.removeClass('dish-redazione');
              cell.addClass('default-exception');
              cell.addClass('exception');
            } else {
              cell.attr("data-dish-id", dataSend.dish_id);
              cell.attr("data-dish-type", 'default');
              cell.removeClass('exception');
              cell.removeClass('default-exception');
              if (!cell.hasClass('dish-redazione')) {
                cell.addClass('dish-redazione');
              }
            }
            if (data.dish_template) {
              $('.dish-wrapper', cell).html(data.dish_template);
            }
            self.hideLoading();
          }
        });
        return false;
      };

      MealPlanner.prototype.deleteDish = function(elem, dishId) {
        var sendData;
        sendData = {
          'action': 'delete_dish',
          'id': dishId,
          'is_ajax': 1
        };
        return $.ajax({
          type: "POST",
          url: ajax_base_url,
          dataType: "json",
          data: sendData,
          success: function(data) {
            var slider;
            slider = self.getSlider();
            slider.removeSlide($(elem).parents('.swiper-slide').index());
            self.showLoading();
            location.reload();
          }
        });
      };

      MealPlanner.prototype.saveException = function() {
        var cell, dataSend, mealId, weekday;
        if ($('#meal-planner-table-desktop .dish-container.exception').length === 2) {
          $('#new-exception .error-message').show();
          w.setTimeout(function() {
            $('#new-exception .error-message').hide();
          }, 3000);
          return;
        }
        weekday = $('input#save-day-id').val();
        mealId = $('input#save-meal-id').val();
        cell = $('.dish-container[data-day-id="' + weekday + '"][data-meal-id="' + mealId + '"]');
        self.showLoadingPopup();
        dataSend = {
          'action': 'save_meal_plan_exception',
          'is_ajax': 1,
          'weekday': weekday,
          'dish_id': null,
          'type': 'exception',
          'meal_id': mealId,
          'user_id': $('#meal-planner-table-desktop').attr("data-user-id")
        };
        return $.ajax({
          type: 'POST',
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.mealplan_id) {
              cell.attr("data-mealplan-id", data.mealplan_id);
              cell.attr('data-dish-type', dataSend.type);
              $('.dish-wrapper', cell).html(data.exception_template);
            }
            cell.removeClass('dish-redazione');
            cell.addClass('exception');
            self.hideLoadingPopup();
            self.closePopup();
          }
        });
      };

      MealPlanner.prototype.saveMeal = function(dishId, type, elem) {
        var cell, dataSend, mealId, weekday;
        weekday = $('input#save-day-id').val();
        mealId = $('input#save-meal-id').val();
        cell = $('.dish-container[data-day-id="' + weekday + '"][data-meal-id="' + mealId + '"]');
        dataSend = {
          'action': 'save_meal_plan',
          'is_ajax': 1,
          'dish_id': dishId,
          'type': type,
          'weekday': weekday,
          'meal_id': mealId,
          'user_id': $('#meal-planner-table-desktop').attr("data-user-id")
        };
        self.showLoadingPopup();
        return $.ajax({
          type: 'POST',
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.mealplan_id) {
              cell.attr("data-dish-id", dataSend.dish_id);
              cell.attr("data-dish-type", dataSend.type);
              cell.attr("data-mealplan-id", data.mealplan_id);
            }
            if (data.dish_template) {
              $('.dish-wrapper', cell).html(data.dish_template);
            }
            cell.removeClass('dish-redazione');
            cell.removeClass('exception');
            cell.removeClass('default-exception');
            self.hideLoadingPopup();
            self.closePopup();
          }
        });
      };

      MealPlanner.prototype.editDish = function(element) {
        var dayId, dishElement;
        dishElement = $(element).parents('.dish-container');
        self.prepareDataForSave($(dishElement));
        $('#modal-popup-dishes').modal('show');
        dayId = $(dishElement).attr('data-day-id');
        $('#modal-popup-dishes .day-referrer').text(' ' + $(dishElement).attr('data-meal-title') + ' ' + self.dayMap[dayId]);
        self.showLoadingPopup();
        return $('#modal-popup-dishes').on('shown.bs.modal', function() {
          self.reinitSlider('#dish-list-slider-redazione');
          self.reinitSlider('#dish-list-slider-user');
          return self.hideLoadingPopup();
        });
      };

      MealPlanner.prototype.viewDish = function(element) {
        var dishElement, dishId, dishType;
        dishElement = $(element).parents('.dish-container');
        $('#modal-popup-view-dish .modal-body div').html('');
        $('#modal-popup-view-dish').modal('show');
        $('#modal-popup-view-dish .loading').show();
        dishId = dishElement.attr('data-dish-id');
        dishType = dishElement.attr('data-dish-type');
        self.getDishDetails(dishId, dishType);
        return self.getDishTemplate(dishId, dishType);
      };

      MealPlanner.prototype.getDishTemplate = function(dishId, dishType) {
        var dataSend;
        dataSend = {
          'is_ajax': 1,
          'dish_id': dishId,
          'type': dishType,
          'action': 'get_dish_simple_template'
        };
        return $.ajax({
          type: "POST",
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.template !== '') {
              $('#modal-popup-view-dish .modal-body .dish-wrap').html(data.template);
            }
          }
        });
      };

      MealPlanner.prototype.getDishDetails = function(dishId, dishType) {
        var dataSend;
        dataSend = {
          'is_ajax': 1,
          'dish_id': dishId,
          'type': dishType,
          'action': 'get_dish_recipes'
        };
        return $.ajax({
          type: "POST",
          url: ajax_base_url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.recipes_template !== '') {
              $('#modal-popup-view-dish .modal-body .recipes-wrap').html(data.recipes_template);
              $('#modal-popup-view-dish .modal-body .name').text(data.dish_name);
              $('#modal-popup-view-dish .modal-body .wrap-button').html(data.recipes_button);
              $('#modal-popup-view-dish .loading').hide();
            }
          }
        });
      };

      MealPlanner.prototype.viewDishDetails = function(element) {
        var currentSlider, dishElement, sliderIdSelector;
        self.showLoadingPopup();
        dishElement = $(element).parents('.swiper-slide');
        sliderIdSelector = '#' + $(element).parents('.aitch-slider').attr('id');
        self.reinitSlider(sliderIdSelector, 1);
        currentSlider = self.getSliderObject(sliderIdSelector);
        currentSlider.slideTo(dishElement.index());
        return self.hideLoadingPopup();
      };

      MealPlanner.prototype.reinitSlider = function(sliderId, slidesPerView) {
        var hidePagination, numSlides, slider, wrapperId;
        slider = self.getSliderObject(sliderId);
        numSlides = $(sliderId).attr('data-slide-desktop');
        if (slidesPerView) {
          numSlides = slidesPerView;
        }
        slider.params.slidesPerView = numSlides;
        wrapperId = $(sliderId).attr('data-wrapper-id');
        if (parseInt($(sliderId).attr('data-slide-count')) <= parseInt(numSlides)) {
          hidePagination = true;
          $('#' + wrapperId + '.dishes-slider .swiper-next').addClass("hidden");
          $('#' + wrapperId + '.dishes-slider .swiper-prev').addClass("hidden");
        } else {
          hidePagination = false;
          $('#' + wrapperId + '.dishes-slider .swiper-next').removeClass("hidden");
          $('#' + wrapperId + '.dishes-slider .swiper-prev').removeClass("hidden");
        }
        slider.params.paginationHide = hidePagination;
        return slider.init();
      };

      MealPlanner.prototype.prepareDataForSave = function(elem) {
        var dayId, mealId, mealplanId;
        mealId = elem.attr('data-meal-id');
        dayId = elem.attr('data-day-id');
        mealplanId = elem.attr('data-mealplan-id');
        $('input#save-meal-id').val(mealId);
        $('input#save-day-id').val(dayId);
        return $('input#save-mealplan-id').val(mealplanId);
      };

      MealPlanner.prototype.clearDataForSave = function() {
        $('input#save-meal-id').val('');
        $('input#save-day-id').val('');
        return $('input#save-mealplan-id').val('');
      };

      MealPlanner.prototype.bindings = function() {
        self.mealTable.on("click", ".meal-container.collapsable .meal-title", function(event) {
          $('.meal-days-container', $(this).parent()).stop(false, true).slideToggle();
          return $(this).parent().toggleClass('active');
        });
        $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
          var sliderElement, sliderId;
          sliderElement = $(e.target.attributes.href.value).find('.aitch-slider');
          if (sliderElement.length > 0) {
            sliderId = sliderElement.attr('id');
            return self.reinitSlider('#' + sliderId);
          }
        });
        $('#modal-popup-dishes').on('hide.bs.modal', function() {
          self.clearDataForSave();
          self.reinitSlider('#dish-list-slider-user');
          return self.reinitSlider('#dish-list-slider-redazione');
        });
        return $('#messages').on("click", "span.close-msg", function(event) {
          return $(this).parent().remove();
        });
      };

      MealPlanner.prototype.getUrlParameter = function(sParam) {
        var i, sPageURL, sParameterName, sURLVariables;
        sPageURL = decodeURIComponent(window.location.search.substring(1));
        sURLVariables = sPageURL.split('&');
        sParameterName = void 0;
        i = void 0;
        i = 0;
        while (i < sURLVariables.length) {
          sParameterName = sURLVariables[i].split('=');
          if (sParameterName[0] === sParam) {
            if (sParameterName[1] === void 0) {
              return true;
            } else {
              return sParameterName[1];
            }
          }
          i++;
        }
      };

      return MealPlanner;

    })();
    w.mealPlanner = new MealPlanner();
    mealPlanner.ready();
  })($, jQuery, document, window);

}).call(this);
